/* ============================================================ */
/* settings */
/* ============================================================ */

/* colors
----------------------------------------------------------- */
$white: #fff;
$black: #000;
$black-lighten: #333;
$gray: #b1b8c1;
$gray-lighten: #d8dee4;

$navy: #001A5F;
$blue: #0066ba;
$blue-lighten: #E4EFF5;
$orange: #FF3C00;
$red: #d80000;

$base-font-color: #000;
$bg-warning: #fffedf;
$bg-danger: #ffefef;
$txt-red: #d80000;
$txt-gray: #6f6f6f;

/* width
----------------------------------------------------------- */
$base-content-width: 1024px;
$content-narrow: 720px;

/* box-shadow
----------------------------------------------------------- */
$base-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);

/* font
----------------------------------------------------------- */

/*----- base-font-family -----*/

$base-font-family: 'Noto Sans JP',
sans-serif;

/*----- base-font-family (serif) -----*/

/*$font-serif: 'Hiragino Mincho ProN', 'Yu Mincho', 游明朝, 'HGS明朝E', serif;*/
