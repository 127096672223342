.c-btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 180px;
  min-height: 44px;
  padding: 8px 24px;
  border-radius: 100px;
  color: $white;
  font-size: 1.6rem;
  text-align: center;
  transition: all .3s;

  &:hover {
    text-decoration: none;
  }

  @include media-pc {
    &:hover {
      transform: scale(1.1);
    }
  }

  @include media-sp {
    min-width: 240px;
  }

  &--orange {
    background: $orange;
  }

  &--gray {
    background: #797979;
  }
}
