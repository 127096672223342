/* text-align
----------------------------------------------------------- */

.u-ta-c {
  text-align: center !important;
}

@include media-pc {
  .u-pc-ta-c {
    text-align: center !important;
  }
}

@include media-sp {
  .u-sp-ta-c {
    text-align: center !important;
  }
}

.u-ta-r {
  text-align: right !important;
}

.u-ta-l {
  text-align: left !important;
}

/* font
----------------------------------------------------------- */

.u-font-medium { font-weight: 500; }
.u-font-bold { font-weight: 700; }
.u-font-red { color: $red; }

.u-font-xxxs { font-size: 1rem !important; }
.u-font-xxs { font-size: 1.2rem !important; }
.u-font-xs { font-size: 1.4rem !important; }
.u-font-s { font-size: 1.6rem !important; }
.u-font-m { font-size: 1.8rem !important; }
.u-font-l { font-size: 2rem !important; }
.u-font-xl { font-size: 2.4rem !important; }
.u-font-xxl { font-size: 2.6rem !important; }
.u-font-xxxl { font-size: 3rem !important; }

@include media-sp {
  .u-font-xxxs { font-size: 1rem !important; }
  .u-font-xxs { font-size: 1.1rem !important; }
  .u-font-xs { font-size: 1.2rem !important; }
  .u-font-s { font-size: 1.4rem !important; }
  .u-font-m { font-size: 1.6rem !important; }
  .u-font-l { font-size: 1.8rem !important; }
  .u-font-xl { font-size: 2rem !important; }
  .u-font-xxl { font-size: 2.2rem !important; }
  .u-font-xxxl { font-size: 2.4rem !important; }
}

/* margin
----------------------------------------------------------- */

.u-mb-0 { margin-bottom: 0 !important; }

.u-mb-xxxs { margin-bottom: 8px !important; }
.u-mb-xxs { margin-bottom: 16px !important; }
.u-mb-xs { margin-bottom: 24px !important; }
.u-mb-s { margin-bottom: 32px !important; }
.u-mb-m { margin-bottom: 40px !important; }
.u-mb-l { margin-bottom: 56px !important; }
.u-mb-xl { margin-bottom: 72px !important; }
.u-mb-xxl { margin-bottom: 80px !important; }
.u-mb-xxxl { margin-bottom: 96px !important; }

@include media-sp {
  .u-mb-xxxs { margin-bottom: 4px !important; }
  .u-mb-xxs { margin-bottom: 8px !important; }
  .u-mb-xs { margin-bottom: 12px !important; }
  .u-mb-s { margin-bottom: 16px !important; }
  .u-mb-m { margin-bottom: 20px !important; }
  .u-mb-l { margin-bottom: 28px !important; }
  .u-mb-xl { margin-bottom: 36px !important; }
  .u-mb-xxl { margin-bottom: 40px !important; }
  .u-mb-xxxl { margin-bottom: 48px !important; }
}

/* flex
-------------------------------------------------- */

.u-d-f { display: flex !important; }

.u-fxd-r { flex-direction: row !important; }
.u-fxd-rr { flex-direction: row-reverse !important; }
.u-fxd-c { flex-direction: column !important; }
.u-fxd-cr { flex-direction: column-reverse !important; }
.u-fxw-n { flex-wrap: nowrap !important; }
.u-fxw-w { flex-wrap: wrap !important; }
.u-fxw-wr { flex-wrap: wrap-reverse !important; }
.u-jc-fs { justify-content: flex-start !important; }
.u-jc-fe { justify-content: flex-end !important; }
.u-jc-c { justify-content: center !important; }
.u-jc-sb { justify-content: space-between !important; }
.u-jc-sa { justify-content: space-around !important; }
.u-ai-fs { align-items: flex-start !important; }
.u-ai-fe { align-items: flex-end !important; }
.u-ai-c { align-items: center !important; }
.u-ai-b { align-items: baseline !important; }
.u-ai-s { align-items: stretch !important; }
.u-ac-fs { align-content: flex-start !important; }
.u-ac-fe { align-content: flex-end !important; }
.u-ac-c { align-content: center !important; }
.u-ac-sb { align-content: space-between !important; }
.u-ac-sa { align-content: space-around !important; }
.u-ac-s { align-content: stretch !important; }
.u-fx-n { flex: none !important; }
.u-as-a { align-self: auto !important; }
.u-as-fs { align-self: flex-start !important; }
.u-as-fe { align-self: flex-end !important; }
.u-as-c { align-self: center !important; }
.u-as-b { align-self: baseline !important; }
.u-as-s { align-self: stretch !important; }

@include media-sp {
  .u-sp-fxd-r { flex-direction: row !important; }
  .u-sp-fxd-rr { flex-direction: row-reverse !important; }
  .u-sp-fxd-c { flex-direction: column !important; }
  .u-sp-fxd-cr { flex-direction: column-reverse !important; }
  .u-sp-fxw-n { flex-wrap: nowrap !important; }
  .u-sp-fxw-w { flex-wrap: wrap !important; }
  .u-sp-fxw-wr { flex-wrap: wrap-reverse !important; }
  .u-sp-jc-fs { justify-content: flex-start !important; }
  .u-sp-jc-fe { justify-content: flex-end !important; }
  .u-sp-jc-c { justify-content: center !important; }
  .u-sp-jc-sb { justify-content: space-between !important; }
  .u-sp-jc-sa { justify-content: space-around !important; }
  .u-sp-ai-fs { align-items: flex-start !important; }
  .u-sp-ai-fe { align-items: flex-end !important; }
  .u-sp-ai-c { align-items: center !important; }
  .u-sp-ai-b { align-items: baseline !important; }
  .u-sp-ai-s { align-items: stretch !important; }
  .u-sp-ac-fs { align-content: flex-start !important; }
  .u-sp-ac-fe { align-content: flex-end !important; }
  .u-sp-ac-c { align-content: center !important; }
  .u-sp-ac-sb { align-content: space-between !important; }
  .u-sp-ac-sa { align-content: space-around !important; }
  .u-sp-ac-s { align-content: stretch !important; }
  .u-sp-fx-n { flex: none !important; }
  .u-sp-as-a { align-self: auto !important; }
  .u-sp-as-fs { align-self: flex-start !important; }
  .u-sp-as-fe { align-self: flex-end !important; }
  .u-sp-as-c { align-self: center !important; }
  .u-sp-as-b { align-self: baseline !important; }
  .u-sp-as-s { align-self: stretch !important; }
}
