.c-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  background: $navy;
  transition: all 1s;

  &.is-loaded {
    opacity: 0;
    visibility: hidden;
  }

  &__image {
    display: flex;
    justify-content: center;
    align-items: center;
    animation-name: fadeIn;
    animation-duration: 1.5s;
  }

  &__logo {
    position: absolute;
    margin: auto;
    min-width: 160px;
    max-width: 280px;

    img {
      width: 100%;
    }

    @include media-sp {
      min-width: 136px;
    }
  }

  &__text {
    padding-top: 208px;
    animation: loadingText 1.8s ease-in-out infinite;
  }
}
