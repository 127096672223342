.c-pagetop {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 15;
  transition: all .3s;

  @include media-pc {
    &:hover {
      bottom: 26px;
    }
  }

  @include media-sp {
    width: 48px;
    height: 48px;
  }
}
