/* top-main-title
 ----------------------------- */
.top-main-title {
  text-align: center;

  @include media-tablet {
    img {
      height: 36px;
    }
  }

  @include media-sp {
    img {
      height: 28px;
    }
  }
}

/* top-hero
 ----------------------------- */
.top-hero {
  overflow: hidden;
  position: relative;
  z-index: -5;
  background: $navy;
  height: 65vw;
  padding-top: 4vw;

  &__cloud {
    position: absolute;
  }

  @include media-pc {
    &__cloud {

      &--01 {
        top: 2vw;
        width: 50%;
        animation: cloud01 6s linear infinite;
      }

      &--02 {
        top: 6vw;
        width: 50%;
        animation: cloud02 6s linear infinite;
      }

      &--03 {
        top: 20vw;
        width: 32%;
        animation: cloud03 5s linear infinite;
      }

      &--04 {
        top: 28vw;
        z-index: 8;
        width: 40%;
        animation: cloud04 5s linear infinite;

        @include media-tablet {
          z-index: -10;
        }
      }
    }
  }

  &__title-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 40vh;
    z-index: -9;
    background: transparent;

    @include media-sp {
      height: 34vh;
    }

    @include media-sp-s {
      height: 44vh;
    }
  }

  &__title {
    position: fixed;
    z-index: -1;

    @include media-ie11 {
      position: static;
    }

    @include media-sp {
      top: 0;
    }
  }

  &__mountain {
    position: relative;
    background: url(/assets/img/top/img_hero_mountain_back.svg) no-repeat center;
    background-position-y: 31vw;
    background-attachment: fixed;
    height: 25vw;
  }

  &__mountain-image {
    @include centering(true, false);
    top: 0;
    width: 100vw;

    img {
      width: 100%;
    }
  }

  &__mountain-people {
    position: absolute;
    left: 39vw;

    img {
      width: 10vw;
    }
  }

  &__scroll {
    @include centering(true, false);
    bottom: 18px;
    animation-name: toBottom;
    animation-duration: 2s;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
  }


  &__wave {
    position: relative;
    width: 100vw;

    &::after {
      position: absolute;
      top: 12vw;
      z-index: 6;
      width: 100%;
      height: 70px;
      background: #001A5F;
      content: "";

    }

    img {
      @include centering(true, false);
      top: -5vw;
      z-index: 5;
      width: 100%;
    }
  }

  @include media-tablet {
    height: 660px;

    &__mountain {
      background-position-y: 40vw;
    }

    &__wave {
      img {
        top: -12vw;
      }
    }

    &__cloud {
      &--03 {
        top: 36vw;
      }
    }
  }

  @include media-sp {
    height: 570px;

    &__cloud {
      width: 60%;
      animation-name: cloudSp;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      animation-direction: alternate;

      &--01 {
        top: -20px;
        left: -60px;
        animation-duration: 3s;
      }

      &--02 {
        top: 60px;
        right: -80px;
        animation-duration: 3.4s;

      }

      &--03 {
        top: 240px;
        left: -120px;
        animation-duration: 2.8s;
      }

      &--04 {
        top: 330px;
        right: 40px;
        z-index: 10;
        width: 40%;
        animation-duration: 3.2s;
      }
    }

    &__title {
      width: 80%;
      margin-top: 40px;
    }

    &__mountain {
      height: 150px;
    }

    &__mountain-image {
      width: 140%;
    }

    &__mountain-people {
      left: 34vw;
      top: -25px;

      img {
        width: 20vw;
      }
    }

    &__wave {

      &::after {
        top: 30px;
        height: 50px;
      }

      img {
        top: -8vw;
      }
    }

    &__scroll {
      bottom: 0;
      img {
        transform: scale(.5);
      }
    }
  }
}

/* top-road
 ----------------------------- */
.top-road {
  position: relative;
  overflow: hidden;
  height: 30vw;
  margin-top: -13vw;
  background:linear-gradient(transparent 0%, transparent 40%, $navy 40%, $navy 100%);

  &::before {
    @include centering(true, false);
      top: 10px;
      z-index: 8;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 966px 568px 966px;
      border-color: transparent transparent $blue-lighten transparent;
      content: "";

      @media (min-width: 1940px) {
        top: 7vw;
      }
  }

  &__sign {
    position: absolute;
    top: 4vw;
    left: 28vw;
    z-index: 6;
    width: 10vw;
    max-width: 160px;
  }

  @include media-tablet {
    height: 32vw;
  }

  @include media-sp {
    margin-top: -108px;

    &::before {
      top: 0;
    }

    &__sign {
      top: 8vw;
      left: 8vw;
      width: 16vw;
    }
  }
}

/* top-service-wrap
 ----------------------------- */
.top-service-wrap {
  padding-bottom: 50vw;
  background: $blue-lighten url(/assets/img/top/img_crack.png) no-repeat bottom;
  background-position: bottom 4vw left 14vw;
  background-size: contain;

  @include media-sp {
    overflow: hidden;
    padding-bottom: 0;
    background: $blue-lighten;
  }
}

/* top-service
 ----------------------------- */
.top-service {
  position: relative;
  z-index: 8;
  padding-bottom: 4em;

  @include media-pc {
    &__crack,
    &__cliff {
      display: none;
    }

    &::before {
      position: absolute;
      top: 33%;
      left: -14vw;
      background: url(/assets/img/top/img_cliff.svg) no-repeat;
      background-size: contain;
      width: 528px;
      height: 376px;
      content: "";

      @include media-tablet {
        left: -24vw;
      }
    }

    &__cloud {

      &--01 {
        top: 5vw;
        width: 30%;
        max-width: 576px;
        animation: cloud05 5s linear infinite;

        img {
          width: 0;

          &.aos-animate {
            width: auto;
          }
        }
      }

      &--02 {
        bottom: -10vw;
        width: 40%;
        max-width: 780px;
        animation: cloud06 5s linear infinite;
      }
    }
  }

  &__cloud {
    position: absolute;
    z-index: 8;
  }

  &__inner {
    max-width: 1000px;
    margin: -16vw auto 0;
    padding: 3em 1.5em 0;
  }

  &__title {
    margin-bottom: 1em;
  }

  @include media-sp {

    &__cloud {
      animation-name: cloudSp;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-duration: 4s;

      &--01 {
        top: 80px;
        right: -60px;
        width: 60%;

      }

      &--02 {
        bottom: 440px;
        left: -130px;
        width: 90%;
      }
    }

    &__inner {
      margin-top: -5vw;
    }

    &__cliff {
      margin: 60px -20px 0 -60px;
    }

    &__crack {
      position: relative;
      width: 100%;
      height: 280px;

      &::before {
        position: absolute;
        right: -20px;
        background: url(/assets/img/top/img_crack@2x.png) no-repeat;
        background-size: cover;
        width: 100%;
        height: 300px;
        content: "";
      }
    }
  }
}

/* top-service-list
 ----------------------------- */
.top-service-list {
  margin-top: 8em;
  &__item {
    &:first-child {
      margin-bottom: 5em;
    }

    &:nth-child(2) {
      margin-bottom: 8em;
    }
  }

  @include media-sp {
    margin-top: 6em;

    &__item {
      &:nth-child(2) {
        margin-bottom: 5em;
      }
    }
  }
}

/* top-service-item
 ----------------------------- */
.top-service-item {
  $this: &;
  position: relative;
  display: flex;
  width: 100%;

  &__mask {
    overflow: hidden;
    width: 60%;
  }

  &__image {
    position: relative;

    img {
      opacity: 0;
    }

    &.is-animate {
      opacity: 1;
      animation-name: maskIn;
      animation-duration: .5s;
      animation-fill-mode: forwards;
      animation-timing-function: cubic-bezier(.8,0,.5,1);
      position: relative;

      img {
        opacity: 1;
      }

      &::after {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        animation-name: maskOut;
        animation-duration: .5s;
        animation-delay: .5s;
        animation-fill-mode: forwards;
        animation-timing-function: cubic-bezier(.8,0,.5,1);
        background: #E4EFF5;
        content: "";
      }
    }
  }

  &__body {
    width: 54%;
    margin-top: 3%;
    margin-left: -10%;
    z-index: 2;
  }

  &__sub-title {
    display: block;
    position: relative;

    &::after {
      position: absolute;
      bottom: -1.25em;
      left: 0;
      width: 60px;
      height: 1px;
      background: #000;
      content: "";
    }
  }

  &__title {
    margin: 1.5em 0 1em;
  }

  &--reverse {
    #{$this}__body {
      margin-right: -10%;
      margin-left: 0;
    }
  }

  @include media-sp {
    flex-direction: column;
    &__body {
      width: 100%;
      margin-left: 0;
    }

    &__heading {
      width: 200px;
      margin-left: auto;
    }

    &__title {
      font-size: 1.4rem;
    }

    &__sub-title {
      img {
        height: 80px;
      }
    }

    &__mask {
      position: absolute;
      top: 0;
      left: 0;
      width: 258px;
      height: 160px;
    }

    &__text {
      padding-top: 2em;
    }

    &--reverse {
      #{$this}__mask {
        right: 0;
        left: inherit;
      }

      #{$this}__heading {
        margin-left: 0;
        margin-right: auto;
      }
    }
  }

  @include media-sp-s {
    &__heading {
      width: 164px;
    }
  }
}

/* top-service-bottom
 ----------------------------- */
.top-service-bottom {
  position: relative;
  height: 160px;
  background: $blue-lighten;

  img {
    @include centering(true, false);
    bottom: -2.2vw;
  }
  @include media-sp {
    height: 72px;
  }
}

/* top-message-and-about
 ----------------------------- */
.top-message-and-about {
  overflow: hidden;
  padding: 6em 0 0;
  background: $navy url(/assets/img/top/bg_message.png) no-repeat;
  background-attachment: fixed;

  @include media-pc-s {
    background-position-y: -112px;
  }

  @include media-sp {
    padding-top: 2em;
    background: $navy url(/assets/img/top/bg_message_sp.png) no-repeat bottom/cover;
    background-attachment: fixed;
    background-position-y: 60px;
  }
}

/* top-message
 ----------------------------- */
.top-message {
  position: relative;
  padding: 3em 1.5em 0;

  &__title {
    margin-bottom: 2em;
  }

  &__inner {
    max-width: 570px;
    margin: 0 auto;

    p {
      color: $blue-lighten;
    }
  }

  @include media-pc {
    margin-bottom: 40px;

    &__cloud {
      position: absolute;

      &--01 {
        bottom: 12vw;
        animation: cloud07 5s linear infinite;
        max-width: 292px;
        width: 30%;
      }

      &--02 {
        bottom: -14px;
        animation: cloud08 4s linear infinite;
      }

      &--03 {
        bottom: -10vw;
        animation: cloud09 5s linear infinite;
      }

      &--04 {
        bottom: -20vw;
        animation: cloud10 4s linear infinite;
      }
    }
  }

  @include media-sp {
    &__inner {
      margin-bottom: 40px;
    }

    &__cloud {
      width: 30%;
      animation-name: cloudSp;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      animation-direction: alternate;


      &--01 {
        margin-bottom: 20px;
        margin-left: 36%;
        animation-duration: 2.4s;
      }

      &--02 {
        margin-left: 64%;
        animation-name: cloudSp02;
        animation-duration: 3s;
      }

      &--03 {
        position: absolute;
        bottom: -10%;
        left: -8%;
        animation-name: cloudSp02;
        animation-duration: 2.8s;
      }

      &--04 {
        position: absolute;
        bottom: -16%;
        animation-duration: 2.6s;
      }
    }
  }
}

/* top-about
 ----------------------------- */
.top-about {
  position: relative;
  padding-top: 27vw;
  background: transparent url(/assets/img/top/bg_about.png) no-repeat top center;


  @include media-pc {
    height: 1648px;
  }

  @include media-pc-s {
    padding-top: 30vw;
  }

  @include media-sp {
    padding-top: 50vw;
    background: transparent url(/assets/img/top/bg_about_sp.png) no-repeat top center;
    background-size: cover;
    /* background-position-x: -592px; */
  }

  &__inner {
    max-width: 570px;
    margin: 0 auto;
    padding: 3em 1.5em 0;

    p {
      margin-bottom: 1.5em;
      color: $white;
      text-align: center;
      line-height: 1.8;
    }
  }

  &__title {
    margin-bottom: 2.5em;
  }

  &__map {
    position: relative;
    margin-top: 4em;

    &::before {
      position: absolute;
      top: -70px;
      left: -82px;
      width: 146px;
      height: 570px;
      background: url(/assets/img/top/img_about_map_climber.png) no-repeat;
      background-size: contain;
      content: "";
    }
    iframe {
      width: 100%;

    }
  }

  &__people {
    img {
      @include centering(true, false);
    bottom: 28px;
    }
  }

  @include media-tablet {
    &__inner {
      padding-top: 6em;
    }
  }

  @include media-sp {

    &__inner {
      padding-top: 2em;

      p {
        margin-bottom: 1em;
        font-size: 1.3rem;
      }
    }

    &__title {
      margin-bottom: 1.25em;
    }

    &__map {
      height: 300px;
      margin-top: 1.5em;
      padding-right: 26px;
      padding-left: 36px;

      &::before {
        top: -38px;
        left: -10px;
        width: 80px;
      }

      iframe {
        height: 100%;
      }
    }

    &__people {
      height: 136px;
      img {
        width: 166px;
        margin-right: auto;
        margin-left: auto;
      }

    }
  }
}

/* top-partner
 ----------------------------- */
.top-partner {
  margin-top: -2px;
  padding: 4em 1.5em;
  background: $blue-lighten;

  &__inner {
    max-width: 680px;
    margin: 0 auto;

    p {
      font-size: 1.4rem;
      text-align: center;
    }
  }

  &__title {
    margin-bottom: 1.5em;
    text-align: center;
  }

  @include media-sp {
    padding-bottom: 0;

    &__inner {
      p {
        text-align: left;
      }
    }
  }
}

/* top-partner-notes
 ----------------------------- */
.top-partner-notes {
  &__title {
    max-width: 434px;
    margin: 4em auto 2em;
    padding: 6px;
    background: $navy;
    color: $white;
    font-size: 1.5rem;
    text-align: center;
  }

  &__text {
    position: relative;

    p {
      padding: 1em 0;
    }

    > div::before,
    > div::after,
    &::before,
    &::after {
      position: absolute;
      width: 9px;
      height: 9px;
      background: url(/assets/img/top/img_frame_plus.svg) no-repeat;
      background-size: contain;
      content: "";
    }

    &::before,
    &::after {
      left: -24px;
    }

    > div {
      &::before {
        right: -24px;
      }
      &::after {
        right: -24px;
        bottom: 0;
      }
    }
  }

  @include media-sp {

    &__title {
      margin-bottom: 1em;
      font-size: 1.4rem;
    }

    &__text {

      p {
        text-align: left;
      }

      > div::before,
      > div::after,
      &::before,
      &::after {
        content: none;
      }
    }
  }
}

/* top-canvas
 ----------------------------- */
.top-canvas {
  overflow: hidden;
  background: $blue-lighten;


  canvas {
    width: 100%;
    height: 300px;
  }

  @include media-tablet {
    canvas {
      height: 208px;
    }
  }
}

/* top-contact
 ----------------------------- */
.top-contact {
  margin-top: -2px;
  padding: 3em 1.5em 7em;
  background: $navy;

  &__inner {
    max-width: 640px;
    margin: 0 auto;
  }

  &__title {
    margin-bottom: 4em;
    text-align: center;
  }

  &__btn {
    margin-top: 3em;
    text-align: center;

    .c-btn {
      margin: 0 1em;

      + .c-btn {
        margin-top: 32px;
      }
    }
  }

  @include media-sp {
    &__title {
      margin-bottom: 2em;
    }
  }
}

.l-header {
  + .top-contact {
    padding-top: 8em;
  }
}

