/* c-form
----------------------------------------------------------- */

.c-form {
  display: flex;
  padding: 0;

  &:not(:first-child) {
    margin-top: 32px;
  }

  &__title {
    width: 24%;
    padding: 0 16px 0 0;
    color: $white;
    font-size: 1.3rem;
  }

  &__body {
    width: 76%;
  }

  &__error-msg {
    margin-bottom: 8px;
    color: $orange;
    font-size: 1.4rem;
    font-weight: 500;
  }

  @include media-sp {
    flex-direction: column;

    &:not(:first-child) {
      margin-top: 20px;
    }

    &__title {
      width: 100%;
      margin-bottom: 6px;
      padding: 0;
    }

    &__body {
      width: 100%;
    }
  }
}

/* c-input
----------------------------------------------------------- */

.c-input {
  color: white;
  font-size: 1.6rem;

  label {
    cursor: pointer;
  }

  &__text {
    width: 100%;
    padding: 12px 16px;
    border-radius: 100px;
    border: 0;

    &:focus {
      background: $bg-warning;
      outline: none;
    }

    &.-small {
      width: 50%;
    }
  }

  &__textarea {
    resize: vertical;
    width: 100%;
    padding: 12px 16px;
    border: none;
    border-radius: 20px;

    &:focus {
      background: $bg-warning;
      outline: none;
    }
  }

  &.is-error {
    input {
      background:#ffb69f;
    }
  }

  @include media-sp {
    &__text,
    &__textarea {
      padding: 10px 16px;
    }
  }
}
