/* logoBounce
---------------------------- */
@keyframes logoBounce {

  0%,
  100% {
    -webkit-transform: scale(0.85)
  }

  50% {
    -webkit-transform: scale(1.0)
  }
}

/* toBottom
---------------------------- */
@keyframes toBottom {
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}


/* fadeIn
---------------------------- */

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* cloud
---------------------------- */

@keyframes cloud01 {
  0% {
    left: -20.625em;
  }

  25% {
    left: -18.75em;
  }

  50% {
    left: -16.875em;
  }

  75% {
    left: -18.75em;
  }

  100% {
    left: -20.625em;
  }
}

@keyframes cloud02 {
  0% {
    right: -330px;
  }

  25% {
    right: -300px;
  }

  50% {
    right: -270px;
  }

  75% {
    right: -300px;
  }

  100% {
    right: -330px;
  }
}

@keyframes cloud03 {
  0% {
    left: 0;
  }

  25% {
    left: 30px;
  }

  50% {
    left: 60px;
  }

  75% {
    left: 30px;
  }

  100% {
    left: 0;
  }
}

@keyframes cloud04 {
  0% {
    right: 130px;
  }

  25% {
    right: 160px;
  }

  50% {
    right: 190px;
  }

  75% {
    right: 160px;
  }

  100% {
    right: 130px;
  }
}

@keyframes cloud05 {
  0% {
    right: 0;
  }

  25% {
    right: 30px;
  }

  50% {
    right: 60px;
  }

  75% {
    right: 30px;
  }

  100% {
    right: 0;
  }
}

@keyframes cloud06 {
  0% {
    left: 100px;
  }

  25% {
    left: 130px;
  }

  50% {
    left: 160px;
  }

  75% {
    left: 130px;
  }

  100% {
    left: 100px;
  }
}

@keyframes cloud07 {
  0% {
    right: -100px;
  }

  25% {
    right: -130px;
  }

  50% {
    right: -160px;
  }

  75% {
    right: -130px;
  }

  100% {
    right: -100px;
  }
}

@keyframes cloud08 {
  0% {
    right: -60px;
  }

  25% {
    right: -30px;
  }

  50% {
    right: 0px;
  }

  75% {
    right: -30px;
  }

  100% {
    right: -60px;
  }
}

@keyframes cloud09 {
  0% {
    left: 100px;
  }

  25% {
    left: 130px;
  }

  50% {
    left: 160px;
  }

  75% {
    left: 130px;
  }

  100% {
    left: 100px;
  }
}

@keyframes cloud10 {
  0% {
    left: 0;
  }

  25% {
    left: 30px;
  }

  50% {
    left: 60px;
  }

  75% {
    left: 30px;
  }

  100% {
    left: 0;
  }
}

@keyframes cloudSp {
  0% {
      transform: translate(0px, 0);
  }
  100% {
      transform: translate(60px, 0);
  }
}

@keyframes cloudSp02 {
  0% {
      transform: translate(0px, 0);
  }
  100% {
      transform: translate(80px, 0);
  }
}

@keyframes cloudSp03 {
  0% {
      transform: translate(0px, 0);
  }
  100% {
      transform: translate(40px, 0);
  }
}

/* loadingText
---------------------------- */

@keyframes loadingText {
  50% {
      opacity: .1;
  }
}


/* service
---------------------------- */

// maskをスライドインする
@keyframes maskIn {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}

//マスク要素をスライドアウトする
@keyframes maskOut {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(100%);
  }
}
