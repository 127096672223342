html {
  font-size: 62.5%;

  @media screen and (min-width: 768px) and (max-width:1024px) {
    font-size: calc(100vw / 102.4);
  }
}

body {
  color: $base-font-color;
  font-family: $base-font-family;
  line-height: 1.8;
  font-size: 1.6rem;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: unset;

  @media only screen and(-webkit-min-device-pixel-ratio: 2),
  (min-resolution: 2dppx) {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

a {
  color: $blue;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

p {
  margin: 0 0 1.75em;
  line-height: 2;

  &:last-child {
    margin-bottom: 0;
  }
}

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

dl,
dl dd {
  margin: 0;
}

table {
  border-collapse: collapse;
}

figure {
  margin: 0;
}

img {
  max-width: 100%;
  vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

fieldset {
  appearance: none;
  margin: 0;
  border: none;
}

button {
  appearance: none;
  margin: 0;
  border: none;
  background: inherit;
  cursor: pointer;
}

button,
input[type="text"],
input[type="email"],
textarea {
  font-family: inherit;
  font-size: inherit;
}

/* PC
----------------------------------------------------------- */

@include media-pc {

  a {
    cursor: pointer;
    @include transition;
  }

  .sp-view {
    display: none !important;
  }
}

/* SP
----------------------------------------------------------- */

@include media-sp {

  body {
    font-size: 1.4rem;
  }

  .pc-view {
    display: none !important;
  }
}
