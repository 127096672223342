.c-title-logo {
  text-align: center;

  svg {
    width: 40vw;
    min-width: 480px;
    max-width: 640px;
  }

  @include media-sp {
    svg {
      min-width: inherit;
      width: 100%;
      height: 260px;
      max-width: 360px;
      margin: auto;
    }
  }

  .svg-elem {
    $this: &;
    fill: transparent;
    // A
    &--01 {
      &.is-active {
        fill: #5cd0d7;
        transition: fill 0.7s cubic-bezier(0.6, -0.28, 0.735, 0.045) 0s;
      }
    }
    // I
    &--02 {
      &.is-active {
        fill: #5cd0d7;
        transition: fill 0.7s cubic-bezier(0.6, -0.28, 0.735, 0.045) 0.15s;
      }
    }
    // M
    &--03 {
      &.is-active {
        fill: #5cd0d7;
        transition: fill 0.7s cubic-bezier(0.6, -0.28, 0.735, 0.045) 0.3s;
      }
    }
    // H
    &--04 {
      &.is-active {
        fill: #ff3c00;
        transition: fill 0.7s cubic-bezier(0.6, -0.28, 0.735, 0.045) 0.45s;
      }
    }
    // I
    &--05 {
      &.is-active {
        fill: #ff3c00;
        transition: fill 0.7s cubic-bezier(0.6, -0.28, 0.735, 0.045) 0.6s;
      }
    }
    //G
    &--06 {
      &.is-active {
        fill: #ff3c00;
        transition: fill 0.7s cubic-bezier(0.6, -0.28, 0.735, 0.045) 0.75s;
      }
    }
    //H
    &--07 {
      &.is-active {
        fill: #ff3c00;
        transition: fill 0.7s cubic-bezier(0.6, -0.28, 0.735, 0.045) 0.9s;
      }
    }
    // Reach Beyond Your Limits
    &--08 {
      &.is-active {
        fill: #fff;
        transition: fill 0.9s cubic-bezier(0.6, -0.28, 0.735, 0.045) 1s;
      }
    }
  }
}
