.contact-thanks {
  color: $white;

  &__title {
    margin-bottom: 1.5em;
    text-align: center;
    font-size: 2rem;
    font-weight: 400;
  }

  p {
    text-align: center;
  }

  &__btn {
    margin-top: 4em;
    text-align: center;
  }
}
