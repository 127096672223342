.l-footer {
  position: relative;
  padding: 4em 2em;

  &__inner {
    display: flex;
    justify-content: space-around;
    max-width: 1000px;
    margin: 0 auto;
  }

  &__nav {
    margin-left: 2em;
  }

  &__link {
    display: flex;
    margin-bottom: 1em;

    > li {
      &:not(:first-child) {
        margin-left: 60px;
      }
    }
  }

  &__address {
    font-size: 1.4rem;
  }

  &__copyright {
    float: right;
    align-self: flex-end;
    margin-top: 1em;
    margin-right: 3em;
    font-size: 1rem;
    letter-spacing: 0.03em;
  }

  @include media-tablet {
    &__copyright {
      position: absolute;
      bottom: 2em;
    }
  }

  @include media-sp {

    &__inner {
      flex-direction: column;
    }

    &__logo {
      order: 2;
      margin: 2.5em 0;
      text-align: center;
    }

    &__nav {
      margin-left: 0;
      order: 1;
    }

    &__link {
      justify-content: center;

      > li {
        &:not(:first-child) {
          margin-left: 24px;
        }
        a {
          display: block;
          img {
            height: 12px;;
          }
        }
      }
    }

    &__address {
      display: none;
    }

    &__copyright {
      align-self: center;
      order: 3;
      margin-right: 0;
    }

  }
}
