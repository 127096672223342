/* ============================================================ */
/* mediaquery */
/* ============================================================ */

@mixin media-ie11 {

  @media screen and (-ms-high-contrast: active),
  (-ms-high-contrast: none) {
    @content;
  }
}

@mixin media-pc {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin media-pc-s {
  @media (max-width: 1280px) {
    @content;
  }
}

@mixin media-tablet {
  @media (max-width: 1024px) {
    @content;
  }
}

@mixin media-sp {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin media-sp-s {
  @media (max-width: 420px) {
    @content;
  }
}

@mixin media-sp-xs {
  @media (max-width: 320px) {
    @content;
  }
}

/* ============================================================ */
/* utility */
/* ============================================================ */

/* circle
----------------------------------------------------------- */
@mixin circle($size) {
  width: $size + px;
  height: $size + px;
  border-radius: 50%;
}

/* transition
----------------------------------------------------------- */
@mixin transition {
  transition: 0.3s all;
}

/* centering
----------------------------------------------------------- */
@mixin centering($horizontal: true, $vertical: true) {
  // @include centering(true, true);
  position: absolute;

  @if $horizontal and $vertical {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @else if $horizontal {
    left: 50%;
    transform: translate(-50%, 0);
  }

  @else if $vertical {
    top: 50%;
    transform: translate(0, -50%);
  }
}
