.l-header {
  position: fixed;
  top: 0;
  z-index: 20;
  width: 100%;
  background: transparent;

  &__inner {
    display: flex;
    justify-content: space-between;
    max-width: 1000px;
    margin: 0 auto;
    padding: 24px;
  }

  @include media-pc {

    &__nav-list {
      display: flex;

      > li {
        &:not(:first-child) {
          margin-left: 3em;
        }
      }
    }

    &__menu-btn {
      display: none;
    }
  }

  @include media-tablet {
    &__logo {
      img {
        height: 60px;
      }
    }
  }

  @include media-sp {

    &__inner {
      padding: 16px;
    }

    &__logo {
      img {
        height: 48px;
      }
    }

    &__nav {
      position: fixed;
      top: 0;
      right: -140px;
      width: 140px;
      padding: 80px 20px 28px;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      background: rgba(#151515, .9);
      transition: left .3s, right .3s;

      &.is-active {
        right: 0;
      }
    }

    &__nav-list {

      > li {
        &:not(:first-child) {
          margin-top: 18px;
        }
        a {
          img {
            height: 12px;
          }
        }
      }
    }

    &__menu-btn {
      display: block;
      position: absolute;
      top: 4px;
      right: 4px;
      z-index: 10;
      width: 50px;
      height: 50px;
      background: transparent;

      &:focus {
        outline: none;
      }

      span {
        display: inline-block;
        position: absolute;
        left: 13px;
        width: 24px;
        height: 2px;
        background: $white;
        transition: all .3s;

        &:first-child {
          top: 18px;
        }

        &:last-child {
          top: 28px;
        }
      }

      &.is-active {
        span {
          &:first-child {
            top: 22px;
            transform: rotate(-45deg);
          }

          &:last-child {
            top: 22px;
            transform: rotate(45deg);
          }
        }
      }
    }
  }
}
